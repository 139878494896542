import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/155df74180b3f95507242ed934df9c01/10017/img1.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAEEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHymmmRQQVyP//EABwQAAIBBQEAAAAAAAAAAAAAAAABEQIDECAhMf/aAAgBAQABBQLNor637JI3On//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAZEAACAwEAAAAAAAAAAAAAAAAgIQABECL/2gAIAQEABj8C13OWDD//xAAbEAEBAQACAwAAAAAAAAAAAAABEQBRoRAgYf/aAAgBAQABPyGfetDnrwjgNEcFISYRr5yKqvp//9oADAMBAAIAAwAAABDQzAz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEAAwABBQAAAAAAAAAAAAABABExISBRYXGh/9oACAEBAAE/EAoPC/Kd77R4duHQ9sXAkKOZcZqRowSiNsXMUVb0f//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/155df74180b3f95507242ed934df9c01/e5166/img1.jpg",
              "srcSet": ["/static/155df74180b3f95507242ed934df9c01/f93b5/img1.jpg 300w", "/static/155df74180b3f95507242ed934df9c01/b4294/img1.jpg 600w", "/static/155df74180b3f95507242ed934df9c01/e5166/img1.jpg 1200w", "/static/155df74180b3f95507242ed934df9c01/10017/img1.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f87a1daef454f6b70a4c26e63b30f96a/10017/img2.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeYzAgoID//EABYQAQEBAAAAAAAAAAAAAAAAABIhMP/aAAgBAQABBQIw5//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABYQAAMAAAAAAAAAAAAAAAAAAAAwMf/aAAgBAQAGPwIq/wD/xAAbEAACAgMBAAAAAAAAAAAAAAAAAREhMDFxgf/aAAgBAQABPyFSRykcR0yK2e4P/9oADAMBAAIAAwAAABAYwDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAVEQEBAAAAAAAAAAAAAAAAAAARIP/aAAgBAgEBPxAj/8QAGhABAQEAAwEAAAAAAAAAAAAAAREAMDFB4f/aAAgBAQABPxCgkero+uFCjPTFDEDxwf/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/f87a1daef454f6b70a4c26e63b30f96a/e5166/img2.jpg",
              "srcSet": ["/static/f87a1daef454f6b70a4c26e63b30f96a/f93b5/img2.jpg 300w", "/static/f87a1daef454f6b70a4c26e63b30f96a/b4294/img2.jpg 600w", "/static/f87a1daef454f6b70a4c26e63b30f96a/e5166/img2.jpg 1200w", "/static/f87a1daef454f6b70a4c26e63b30f96a/10017/img2.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/248f6670f6fb67647b970b5aa1c2a28c/10017/img3.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHypunJRpkEH//EABkQAAMAAwAAAAAAAAAAAAAAAAABERAgMf/aAAgBAQABBQLK4Mpdf//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABoQAAICAwAAAAAAAAAAAAAAAAExESAAECH/2gAIAQEAAT8hjjqNggNC6f/aAAwDAQACAAMAAAAQI8w8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAMAAAcAAAAAAAAAAAAAAQARIRAgMUFRYZH/2gAIAQEAAT8QRV0vao8OhUXyH2akgNyV9xDY5P/Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/248f6670f6fb67647b970b5aa1c2a28c/e5166/img3.jpg",
              "srcSet": ["/static/248f6670f6fb67647b970b5aa1c2a28c/f93b5/img3.jpg 300w", "/static/248f6670f6fb67647b970b5aa1c2a28c/b4294/img3.jpg 600w", "/static/248f6670f6fb67647b970b5aa1c2a28c/e5166/img3.jpg 1200w", "/static/248f6670f6fb67647b970b5aa1c2a28c/10017/img3.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c204aa42d8532342e23a667232928061/10017/img4.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHypsc1G2AQf//EABYQAQEBAAAAAAAAAAAAAAAAACABEf/aAAgBAQABBQIYKP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABUQAQEAAAAAAAAAAAAAAAAAACAx/9oACAEBAAY/AnT/AP/EABsQAAIDAAMAAAAAAAAAAAAAAAERABAxICGB/9oACAEBAAE/IUE35YAVDsGbOo1w/9oADAMBAAIAAwAAABADxDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAeEAACAQMFAAAAAAAAAAAAAAABEQAxQWEQICGBkf/aAAgBAQABPxCxXhqcBI5hGwgpIq0dwkKl7EoLzs//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/c204aa42d8532342e23a667232928061/e5166/img4.jpg",
              "srcSet": ["/static/c204aa42d8532342e23a667232928061/f93b5/img4.jpg 300w", "/static/c204aa42d8532342e23a667232928061/b4294/img4.jpg 600w", "/static/c204aa42d8532342e23a667232928061/e5166/img4.jpg 1200w", "/static/c204aa42d8532342e23a667232928061/10017/img4.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/427c15c15e3bd56578940689d6a1dcf9/10017/img5.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHypsc1G2AQf//EABcQAAMBAAAAAAAAAAAAAAAAAAABIBD/2gAIAQEAAQUClixx/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAHBAAAgICAwAAAAAAAAAAAAAAABEBMRAhIGGB/9oACAEBAAE/IVCvzMJGui2hEOC3D//aAAwDAQACAAMAAAAQE8Q8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAGxABAQADAAMAAAAAAAAAAAAAAREAEGEgMUH/2gAIAQEAAT8QYWbxsxoao5cxA2XuJ/TELT14f//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/427c15c15e3bd56578940689d6a1dcf9/e5166/img5.jpg",
              "srcSet": ["/static/427c15c15e3bd56578940689d6a1dcf9/f93b5/img5.jpg 300w", "/static/427c15c15e3bd56578940689d6a1dcf9/b4294/img5.jpg 600w", "/static/427c15c15e3bd56578940689d6a1dcf9/e5166/img5.jpg 1200w", "/static/427c15c15e3bd56578940689d6a1dcf9/10017/img5.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/9620700648fb4144e6ba0e5b1fbe8475/10017/img6.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHysvTmC0AD/8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAIDFB/9oACAEBAAEFAuIZCzT/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAcEAACAgIDAAAAAAAAAAAAAAAAMQERECAhUYH/2gAIAQEAAT8hprKyewMQkc9Daf/aAAwDAQACAAMAAAAQoMIw/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRAAAwACAgMAAAAAAAAAAAAAAAERMVEQYSFxkf/aAAgBAQABPxCYSnqFaZjJg8pLsbDLXTHK7PT8NihXJXCvb4//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/9620700648fb4144e6ba0e5b1fbe8475/e5166/img6.jpg",
              "srcSet": ["/static/9620700648fb4144e6ba0e5b1fbe8475/f93b5/img6.jpg 300w", "/static/9620700648fb4144e6ba0e5b1fbe8475/b4294/img6.jpg 600w", "/static/9620700648fb4144e6ba0e5b1fbe8475/e5166/img6.jpg 1200w", "/static/9620700648fb4144e6ba0e5b1fbe8475/10017/img6.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c24ec5239a8a3324f176798d810ad945/10017/img7.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeVNDIKgIP/EABYQAQEBAAAAAAAAAAAAAAAAABEgIf/aAAgBAQABBQIymf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EAB0QAAIABwEAAAAAAAAAAAAAAAABEBEgITFBUZH/2gAIAQEAAT8h2EnyCweDE7Dpf//aAAwDAQACAAMAAAAQ8MwM/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHBAAAgIDAQEAAAAAAAAAAAAAAAERMSFRkSBh/9oACAEBAAE/EKEcvUH0cHix8a6ONdMtghRZGOp6Ny58f//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/c24ec5239a8a3324f176798d810ad945/e5166/img7.jpg",
              "srcSet": ["/static/c24ec5239a8a3324f176798d810ad945/f93b5/img7.jpg 300w", "/static/c24ec5239a8a3324f176798d810ad945/b4294/img7.jpg 600w", "/static/c24ec5239a8a3324f176798d810ad945/e5166/img7.jpg 1200w", "/static/c24ec5239a8a3324f176798d810ad945/10017/img7.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/20000ed084c96ee5466e0663cffd1f33/10017/img8.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeVNjANsQqD/xAAXEAADAQAAAAAAAAAAAAAAAAABESAh/9oACAEBAAEFAllmP//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EAB0QAAICAQUAAAAAAAAAAAAAAAAxAREQIEFhgaH/2gAIAQEAAT8h3LzCZfPhLIR2No//2gAMAwEAAgADAAAAEIDNPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB4QAQEAAQMFAAAAAAAAAAAAAAERACFRgRAgMUGR/9oACAEBAAE/EAMBdpiJ56NmRxlYO6sNOqc4ge/3NhvZ/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/20000ed084c96ee5466e0663cffd1f33/e5166/img8.jpg",
              "srcSet": ["/static/20000ed084c96ee5466e0663cffd1f33/f93b5/img8.jpg 300w", "/static/20000ed084c96ee5466e0663cffd1f33/b4294/img8.jpg 600w", "/static/20000ed084c96ee5466e0663cffd1f33/e5166/img8.jpg 1200w", "/static/20000ed084c96ee5466e0663cffd1f33/10017/img8.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/0e3a631cbdf6528e693d56e08c1e3e96/10017/img9.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHlTOmsFQEH/8QAFhABAQEAAAAAAAAAAAAAAAAAARAg/9oACAEBAAEFAqR3/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAHBAAAgICAwAAAAAAAAAAAAAAABEBIRAgMWGB/9oACAEBAAE/IVTzKh9Sch0e6//aAAwDAQACAAMAAAAQgMwM/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAQEAAgEFAAAAAAAAAAAAAREAITEgQVFxkfD/2gAIAQEAAT8QmQ1fQNH2d13oHhfjVpV0mBBZ96n455fD/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/0e3a631cbdf6528e693d56e08c1e3e96/e5166/img9.jpg",
              "srcSet": ["/static/0e3a631cbdf6528e693d56e08c1e3e96/f93b5/img9.jpg 300w", "/static/0e3a631cbdf6528e693d56e08c1e3e96/b4294/img9.jpg 600w", "/static/0e3a631cbdf6528e693d56e08c1e3e96/e5166/img9.jpg 1200w", "/static/0e3a631cbdf6528e693d56e08c1e3e96/10017/img9.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/163f3034c0caf6be3384d7a433d5c739/10017/img10.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHlV0GaRZUED//EABcQAAMBAAAAAAAAAAAAAAAAAAARIAH/2gAIAQEAAQUCnRjn/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwJP/8QAHBAAAQQDAQAAAAAAAAAAAAAAAQAQETEgIUGR/9oACAEBAAE/IY1biuLxWQgGHD//2gAMAwEAAgADAAAAEFPMPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB8QAQACAQMFAAAAAAAAAAAAAAEAESEQIDFBYYGhwf/aAAgBAQABPxCtXJelasrChzGT48QgPke/1Fbez//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/163f3034c0caf6be3384d7a433d5c739/e5166/img10.jpg",
              "srcSet": ["/static/163f3034c0caf6be3384d7a433d5c739/f93b5/img10.jpg 300w", "/static/163f3034c0caf6be3384d7a433d5c739/b4294/img10.jpg 600w", "/static/163f3034c0caf6be3384d7a433d5c739/e5166/img10.jpg 1200w", "/static/163f3034c0caf6be3384d7a433d5c739/10017/img10.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3d25ba14dc4981fc54f5bf956f33fa51/10017/img11.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeVNjAKgIP/EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEAAQUCT//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABQQAQAAAAAAAAAAAAAAAAAAADD/2gAIAQEABj8CT//EABwQAAICAgMAAAAAAAAAAAAAAAARASEQMSBBkf/aAAgBAQABPyFUxTiNdnpOx0OOP//aAAwDAQACAAMAAAAQwMwM/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhABAAIBBAMAAAAAAAAAAAAAAQARMRBBkaEgIfD/2gAIAQEAAT8QxhtdgiGzxpetcI39JfLuBKVFsj1H2+H/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/3d25ba14dc4981fc54f5bf956f33fa51/e5166/img11.jpg",
              "srcSet": ["/static/3d25ba14dc4981fc54f5bf956f33fa51/f93b5/img11.jpg 300w", "/static/3d25ba14dc4981fc54f5bf956f33fa51/b4294/img11.jpg 600w", "/static/3d25ba14dc4981fc54f5bf956f33fa51/e5166/img11.jpg 1200w", "/static/3d25ba14dc4981fc54f5bf956f33fa51/10017/img11.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/621547f647b9fbdd7850a5e6898f7055/10017/img12.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "142.00000000000003%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAcABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAeXNCQAGD//EABUQAQEAAAAAAAAAAAAAAAAAABEw/9oACAEBAAEFAin/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ak//xAAbEAADAAIDAAAAAAAAAAAAAAAAARExURAgYf/aAAgBAQABPyHC0j1wsFWh5L6V9f/aAAwDAQACAAMAAAAQcMwM/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHhAAAgEEAwEAAAAAAAAAAAAAAAERITFRkSBBYYH/2gAIAQEAAT8QU+5iD0aHS4yS0n6PE2OnJKBNaSHkey/D/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "a",
              "title": "a",
              "src": "/static/621547f647b9fbdd7850a5e6898f7055/e5166/img12.jpg",
              "srcSet": ["/static/621547f647b9fbdd7850a5e6898f7055/f93b5/img12.jpg 300w", "/static/621547f647b9fbdd7850a5e6898f7055/b4294/img12.jpg 600w", "/static/621547f647b9fbdd7850a5e6898f7055/e5166/img12.jpg 1200w", "/static/621547f647b9fbdd7850a5e6898f7055/10017/img12.jpg 1471w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      